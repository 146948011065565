<template>
  <section id="developers-section" class="section">
    <div class="container">
      <div
        class="title centered-title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <h2>Nuestro Equipo</h2>
        <p class="subtitle">Conozca al equipo detras de Fancy Solutions.</p>
      </div>
      <div class="content">
        <div class="developers-container">
          <div
            class="developer"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="image">
              <img
                src="@/assets/img/developers/dev-1.png"
                alt="Desarrollador"
                loading="lazy"
              />
            </div>
            <div class="description">
              <p class="name">Maximo Trufelman</p>
              <p class="role">Desarrollador Web - Desarrollador Movil</p>
              <div class="social-networks">
                <a
                  href="https://www.facebook.com/maxi.tru.16/"
                  target="_blank"
                  class="btn-social"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  href="https://www.instagram.com/maxi_tru/"
                  target="_blank"
                  class="btn-social"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  href="mailto:maxi@fancy-solutions.com"
                  target="_blank"
                  class="btn-social"
                  ><i class="fas fa-envelope"></i
                ></a>
              </div>
            </div>
          </div>
          <div
            class="developer"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="image">
              <img
                src="@/assets/img/developers/dev-2.png"
                alt="Desarrollador"
                loading="lazy"
              />
            </div>
            <div class="description">
              <p class="name">Augusto Trufelman</p>
              <p class="role">Desarrollador Web - Desarrollador Movil</p>
              <div class="social-networks">
                <a
                  href="https://www.facebook.com/au.tru.739"
                  target="_blank"
                  class="btn-social"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a
                  href="https://www.instagram.com/autru0506/"
                  target="_blank"
                  class="btn-social"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a
                  href="mailto:augusto@fancy-solutions.com"
                  target="_blank"
                  class="btn-social"
                  ><i class="fas fa-envelope"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.section {
  background-image: url("~@/assets/img/developers/developers-image.png");
  background-size: cover;
}
.developers-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  max-width: 850px;
  margin: 0 auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .developer {
    flex: 0 0 100%;
    background-color: $extremeLightGray;
    border-radius: 5px;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out,
      background-color 0.3s ease-in-out;

    &:hover {
      box-shadow: 2px 12px 38px 1px #00000025;
      transform: translateY(-5px);
      background-color: $white;

      .description {
        .social-networks {
          .btn-social {
            background-color: $extremeLightGray;

            &:hover {
              background-color: $darkBlue;
            }
          }
        }
      }
    }

    .image {
      height: 350px;

      img {
        object-fit: cover;
      }
    }
  }
  .description {
    padding: 25px 20px;
    text-align: center;

    .name {
      color: $darkBlue;
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: 600;
    }
    .role {
      color: $darkGray;
    }
    .social-networks {
      display: flex;
      justify-content: center;
      margin-top: 25px;

      .btn-social {
        background-color: $white;
        height: 40px;
        width: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        margin-right: 15px;
        color: $darkBlue;
        box-shadow: 0px 0px 0px 0px $darkBlue;
        transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;

        &:hover {
          transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out,
            box-shadow 0.5s ease-in-out;
          background-color: $darkBlue;
          color: $white;
          box-shadow: 0px 0px 0px 10px transparent;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
</style>