<template>
  <section id="technologies-section" class="section section-filled-light">
    <div class="container">
      <div
        class="title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <span class="decoration">Integraciones</span>
        <h2>Tecnologias</h2>
        <p class="subtitle">
          Utilizamos lo ultimo en tecnologías de desarrollo para lograr los
          mejores resultados.
        </p>
      </div>
      <div class="content">
        <div class="technologies-container">
          <div
            v-for="item in getLogos"
            :key="item"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="technology">
              <div class="info">
                <div class="image">
                  <img :src="getImgUrl(item.img)" :alt="item.title" />
                </div>
                <p>{{ item.title }}</p>
              </div>
              <div class="absolute-image">
                <img :src="getImgUrl(item.img)" :alt="item.title" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from "@vue/reactivity";
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
    const logos = [
      {
        title: "HTML",
        img: "html.png",
      },
      {
        title: "CSS",
        img: "css.png",
      },
      {
        title: "SASS",
        img: "sass.png",
      },
      {
        title: "Bootstrap",
        img: "bootstrap.png",
      },
      {
        title: "JavaScript",
        img: "javascript.png",
      },
      {
        title: "jQuery",
        img: "jquery.png",
      },
      {
        title: "Vue.js",
        img: "vue.png",
      },
      {
        title: "PHP",
        img: "php.png",
      },
      {
        title: "Node.js",
        img: "nodejs.png",
      },
      {
        title: "Express",
        img: "express.png",
      },
      {
        title: "NPM",
        img: "npm.png",
      },
      {
        title: "SQL",
        img: "sql.png",
      },
      {
        title: "Firebase",
        img: "firebase.png",
      },
      {
        title: "Flutter",
        img: "flutter.png",
      },
      {
        title: "Dart",
        img: "dart.png",
      },
      {
        title: "Python",
        img: "python.png",
      },
      {
        title: "Visual Studio Code",
        img: "vscode.png",
      },
      {
        title: "Figma",
        img: "figma.png",
      },
      {
        title: "GitHub",
        img: "github.png",
      },
      {
        title: "Android",
        img: "android.png",
      },
      {
        title: "Socket.io",
        img: "socketio.png",
      },
      {
        title: "Ejs",
        img: "ejs.png",
      },
      {
        title: "Wordpress",
        img: "wordpress.png",
      },
      {
        title: "Woocomerce",
        img: "woocomerce.png",
      },
    ];
    return {
      getLogos: computed(() => logos),
    };
  },
  methods: {
    getImgUrl(img) {
      return require(`../../../assets/img/technologies/${img}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.technologies-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
  @media (min-width: 400px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (min-width: 1000px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (min-width: 1400px) {
    grid-template-columns: repeat(7, 1fr);
  }
  .technology {
    background-color: $white;
    padding: 20px;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 0px 30px 3px #00000028;
      .absolute-image {
        transform: translateX(50%) scale(2);
      }
    }
    .info {
      position: relative;
      z-index: 2;

      .image {
        margin: 0 auto;
        height: 60px;
        max-width: 90px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          height: auto;
          text-align: center;
        }
      }
      p {
        text-align: center;
        margin: auto;
        margin-top: 10px;
        font-weight: 500;
        max-width: 110px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .absolute-image {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 1;
      opacity: 0.07;
      display: flex;
      justify-content: center;
      align-items: center;
      transform: translateX(100%);
      transition: transform 0.3s ease-in-out;

      img {
        width: auto;
        height: auto;
      }
    }
  }
}
</style>