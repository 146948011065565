<template>
  <section
    id="about-us-section"
    @mousemove="moveElements"
    class="section section-filled-light"
  >
    <div class="container">
      <div class="section-flex about-us-container">
        <div class="left-side">
          <div
            class="title"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <span class="decoration">Nuestra Compañia</span>
            <h2>Acerca de Nosotros</h2>
          </div>
          <div
            class="content"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="description">
              <p>
                Fancy Solutions es una empresa independiente de consultoría
                tecnológica y desarrollo de software con sede en la ciudad de
                Puerto Iguazu. Ayudamos a organizaciones que van desde nuevas
                empresas hasta grandes empresas a modernizar y alinear su
                tecnología con el futuro. Comenzamos con un enfoque consultivo
                basado en la comprensión informada y la causa común. En Fancy
                Solutions, brindamos empatía, colaboración y propósito
                compartido a todas las relaciones con nuestros clientes.
              </p>
              <p>
                Nuestra diferencia es una capacidad sin igual para reducir el
                riesgo de proyectos de software complejos. Fancy Solutions
                entrega de manera confiable soluciones vitales a tiempo y dentro
                del presupuesto.
              </p>
              <p>
                Fundada en 2020, nuestra sede se encuentra en la ciudad de
                Puerto Iguazu y cuenta con el respaldo de un equipo de
                profesionales talentosos comprometidos con la construcción de
                cosas de valor duradero.
              </p>
            </div>
          </div>
        </div>
        <div
          class="right-side image-container"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
        >
          <div id="absolute-square-1" class="absolute-square-1"></div>
          <div id="absolute-square-2" class="absolute-square-2"></div>
          <div class="image">
            <img
              src="@/assets/img/about-us/about-us-img.jpg"
              alt="Acerca de Nosotros"
              loading="lazy"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
    function moveElements(e) {
      const section = document.querySelector("#about-us-section");
      const sectionHeightCalculation = (section.clientHeight - e.pageX) * 0.02;
      const sectionWidthCalculation = (section.clientWidth - e.pageY) * 0.02;

      document.querySelector("#absolute-square-1").style.transform =
        "translate(" +
        sectionHeightCalculation +
        "px," +
        sectionWidthCalculation +
        "px)";

      document.querySelector("#absolute-square-2").style.transform =
        "translate(" +
        sectionHeightCalculation +
        "px," +
        sectionWidthCalculation +
        "px)";
    }

    return { moveElements };
  },
};
</script>

<style lang="scss" scoped>
.about-us-container {
  .left-side {
    .description {
      color: $darkGray;

      p {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  .image-container {
    position: relative;

    .image {
      position: relative;
      z-index: 1;
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @media (min-width: 1200px) {
        padding: 30px;
      }

      img {
        border-radius: 0;
        object-fit: cover;
        max-height: 400px;
        object-fit: cover;
        transition: box-shadow 0.3s ease-in-out;

        &:hover {
          box-shadow: 5px 5px 22px -5px #00000041;
        }

        @media (min-width: 1200px) {
          border-radius: 5px;
        }
      }
    }

    .absolute-square-1 {
      position: absolute;
      top: 0;
      left: 0;
      height: 100px;
      width: 300px;
      background-color: #ff8800;
      z-index: 0;
      border-radius: 4px;
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }
    }

    .absolute-square-2 {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 100px;
      width: 300px;
      background-color: #00ac0e;
      z-index: 0;
      border-radius: 4px;
      display: none;

      @media (min-width: 1200px) {
        display: block;
      }
    }
  }
}
</style>