<template>
  <Hero></Hero>
  <Services></Services>
  <AboutUs></AboutUs>
  <Team></Team>
  <Technologies></Technologies>
  <Projects></Projects>
  <FrequentQuestions></FrequentQuestions>
  <ContactUs></ContactUs>
</template>


<script>
import Hero from "@/views/home/components/Hero";
import Services from "@/views/home/components/Services";
import AboutUs from "@/views/home/components/AboutUs";
import Team from "@/views/home/components/Team";
import Technologies from "@/views/home/components/Technologies";
import Projects from "@/views/home/components/Projects";
import FrequentQuestions from "@/views/home/components/FrequentQuestions";
import ContactUs from "@/views/home/components/ContactUs";

export default {
  components: {
    Hero,
    Services,
    Technologies,
    AboutUs,
    Team,
    Projects,
    FrequentQuestions,
    ContactUs,
  },
};
</script>
