<template>
  <section id="projects-section" @mousemove="moveElements" class="section">
    <div class="container">
      <div
        class="title centered-title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <span class="decoration">Nuestros Trabajos</span>
        <h2>Proyectos</h2>
        <p class="subtitle">
          Contamos con un largo trayecto realizando proyectos para diversos
          clientes en todas las áreas.
        </p>
      </div>
      <div
        class="content"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <div class="projects-container" ref="proj_device_container">
          <div v-tilt="{ max: 5 }" class="project-img-container project-iphone">
            <img
              id="absolute-phone"
              src="@/assets/img/projects/iphone.png"
              alt="Proyecto"
              loading="lazy"
            />
          </div>
          <div
            v-tilt="{ max: 5 }"
            class="project-img-container project-macbook"
          >
            <img
              id="absolute-laptop"
              src="@/assets/img/projects/macbook.png"
              alt="Proyecto"
              loading="lazy"
            />
          </div>
          <div
            ref="ipad_container"
            class="project-img-container project-ipad"
            id="projimg"
            loading="lazy"
          >
            <img
              id="absolute-ipad"
              src="@/assets/img/projects/ipad.png"
              alt="Proyecto"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
  },
  mounted() {
    window.addEventListener("resize", this.resizeProjContainer);
    window.addEventListener("load", this.resizeProjContainer);
    var container = document.getElementById("projimg");
    var inner = document.getElementById("absolute-ipad");
    var counter = 0;
    var updateRate = 10;
    var isTimeToUpdate = function () {
      return counter++ % updateRate === 0;
    };
    var onMouseEnterHandler = function (event) {
      update(event);
    };
    var onMouseLeaveHandler = function () {
      inner.style = "";
    };
    var onMouseMoveHandler = function (event) {
      if (isTimeToUpdate()) {
        update(event);
      }
    };
    container.onmouseenter = onMouseEnterHandler;
    container.onmouseleave = onMouseLeaveHandler;
    container.onmousemove = onMouseMoveHandler;
    var mouse = {
      _x: 0,
      _y: 0,
      x: 0,
      y: 0,
      updatePosition: function (event) {
        var e = event || window.event;
        this.x = e.clientX - this._x;
        this.y = (e.clientY - this._y) * -1;
      },
      setOrigin: function (e) {
        this._x = e.offsetLeft + Math.floor(e.offsetWidth / 2);
        this._y = e.offsetTop + Math.floor(e.offsetHeight / 2);
      },
      show: function () {
        return "(" + this.x + ", " + this.y + ")";
      },
    };
    // Track the mouse position relative to the center of the container.
    mouse.setOrigin(container);
    var update = function (event) {
      mouse.updatePosition(event);
      updateTransformStyle(
        (mouse.y / inner.offsetHeight / 2).toFixed(2),
        (mouse.x / inner.offsetWidth / 2).toFixed(2)
      );
    };
    var updateTransformStyle = function (x, y) {
      var style = "rotateX(" + x + "deg) rotateY(" + y + "deg)";
      inner.style.transform = style;
      inner.style.webkitTransform = style;
      inner.style.mozTransform = style;
      inner.style.msTransform = style;
      inner.style.oTransform = style;
    };
  },
  created() {
    window.addEventListener("resize", this.resizeProjContainer);
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeProjContainer);
  },
  methods: {
    resizeProjContainer() {
      let projDevContainer = this.$refs.proj_device_container;
      let ipadContainer = this.$refs.ipad_container;
      let ipadContainerHeight = ipadContainer.clientHeight;
      projDevContainer.style["height"] =
        ipadContainerHeight + ipadContainerHeight * 0.3 + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
.projects-container {
  height: 175px;
  margin: 0 auto;
  position: relative;
  max-width: 90%;
  @media (min-width: 768px) {
    max-width: 80%;
  }
  .project-img-container {
    position: absolute;
    perspective: 40px;
    &.project-macbook {
      bottom: 0;
      right: 0%;
      transform: translateX(-0%);
      max-width: 58%;
      z-index: 1;
    }
    &.project-iphone {
      bottom: 0;
      left: 15%;
      transform: translateX(-15%);
      max-width: 17%;
      z-index: 1;
    }
    &.project-ipad {
      bottom: 10%; //90px
      left: 35%;
      transform: translate(-35%, -10%);
      max-width: 23%;
      z-index: 0;
      img {
        transition: transform 0.3s;
      }
    }
  }
}
</style>