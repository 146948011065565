<template>
  <section id="hero-section" @mousemove="moveElements" class="hero">
    <div class="absolute-huge-circle">
      <img
        src="@/assets/img/utilities/blob.svg"
        alt="Fondo Hero"
        loading="lazy"
      />
    </div>
    <div id="absolute-donut" class="absolute-donut">
      <img
        src="@/assets/img/hero/hero-donut.png"
        alt="Fondo Hero"
        loading="lazy"
      />
    </div>
    <div class="container hero-container">
      <div
        data-aos="fade-right"
        data-aos-anchor-placement="center-bottom"
        class="information-container"
      >
        <div id="absolute-hero-dots" class="absolute-dots">
          <img
            src="@/assets/img/hero/hero-dots.png"
            alt="Fondo Hero"
            loading="lazy"
          />
        </div>
        <h1>
          Fancy Solutions
          <span class="hihghlited">
            <vue-writer :array="['Paginas Web', 'Aplicaciones Moviles']" />
          </span>
        </h1>
        <p class="description">
          Transformamos tu negocio con soluciones digitales adaptables que
          satisfacen las necesidades de hoy y desbloquean las oportunidades del
          mañana.
        </p>
        <div class="buttons-container">
          <a href="#contact-us-section" class="btn btn-filled">
            <span class="text">Contactar</span>
            <span class="line top"></span>
            <span class="line bottom"></span>
          </a>
          <a href="#services-section" class="btn btn-transparent">
            <span class="text">Conoce Mas</span>
            <span class="line top"></span>
            <span class="line bottom"></span>
          </a>
        </div>
        <div class="location">
          <i class="fas fa-map-marker-alt"></i>
          <p>Argentina</p>
        </div>
      </div>
      <div
        data-aos="fade-left"
        data-aos-anchor-placement="center-bottom"
        class="image-container"
      >
        <img
          src="@/assets/img/hero/hero-image.png"
          alt="Fondo Hero"
          loading="lazy"
        />
      </div>
    </div>
  </section>
</template>

<script>
import VueWriter from "vue-writer";
import AOS from "aos";

export default {
  components: { VueWriter },
  setup() {
    AOS.init({
      once: true,
    });
    function moveElements(e) {
      const section = document.querySelector("#hero-section");
      const sectionHeightCalculation = (section.clientHeight - e.pageX) * 0.02;
      const sectionWidthCalculation = (section.clientWidth - e.pageY) * 0.02;

      document.querySelector("#absolute-hero-dots").style.transform =
        "translate(" +
        sectionHeightCalculation +
        "px," +
        sectionWidthCalculation +
        "px)";

      document.querySelector("#absolute-donut").style.transform =
        "translate(" +
        sectionHeightCalculation +
        "px," +
        sectionWidthCalculation +
        "px)";
    }

    return { moveElements };
  },
};
</script>

<style lang="scss">
.hero {
  background: $backgroundGradient;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
  padding: 150px 0 50px 0;
  position: relative;

  .absolute-huge-circle {
    height: 450px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    opacity: 0.7;

    @media (min-width: 1100px) {
      top: 0;
      left: 55%;
      height: auto;
    }

    img {
      transform: translateY(240px) scale(1.6);
      animation: rotate-circle 50s infinite linear;

      @keyframes rotate-circle {
        from {
          transform: translateY(240px) scale(1.6) rotate(0deg);
        }
        to {
          transform: translateY(240px) scale(1.6) rotate(360deg);
        }
      }

      @media (min-width: 850px) {
        @keyframes rotate-circle {
          from {
            transform: translateY(240px) scale(1.7) rotate(0deg);
          }
          to {
            transform: translateY(240px) scale(1.7) rotate(360deg);
          }
        }
      }

      @media (min-width: 1100px) {
        @keyframes rotate-circle {
          from {
            transform: translateX(300px) scale(1.3) rotate(0deg);
          }
          to {
            transform: translateX(300px) scale(1.3) rotate(360deg);
          }
        }
      }
    }
  }
  .absolute-donut {
    position: absolute;
    right: 0px;
    top: 350px;
    display: none;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    width: 300px;

    @media (min-width: 900px) {
      display: flex;
    }

    @media (min-width: 1100px) {
      left: 300px;
      right: auto;
      top: auto;
      bottom: 0;
    }

    img {
      transform: translateX(70%);

      @media (min-width: 1100px) {
        transform: translateY(70%);
      }
    }
  }
  .hero-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;

    .information-container {
      position: relative;
      flex: 1;
      color: $white;

      @media (min-width: 1100px) {
        flex: 0 0 calc(55% - 20px);
      }

      .absolute-dots {
        position: absolute;
        width: 80px;
        height: 80px;
        top: 0;
        z-index: -1;

        @media (min-width: 1100px) {
          top: -100px;
        }
      }

      h1 {
        font-size: 50px;
        line-height: 1.3;
        word-break: break-word;

        .hihghlited {
          display: block;

          .is-typed span.typed {
            color: $primaryGreen;
          }

          .is-typed span.cursor {
            display: inline-block;
            width: 3px;
            animation: blink 1s infinite;
          }

          @keyframes blink {
            49% {
              background-color: $white;
            }
            50% {
              background-color: transparent;
            }
            99% {
              background-color: transparent;
            }
          }
        }
      }
      .description {
        margin-top: 15px;
        color: $lightGray;
        font-size: 17px;
      }
      .buttons-container {
        display: flex;
        margin-top: 35px;

        .btn {
          position: relative;
          margin-right: 15px;
          text-decoration: none;
          box-shadow: 0px 0px 0px 0px #0077ff;
          overflow: hidden;

          .text {
            position: relative;
            z-index: 2;
          }

          .line {
            position: absolute;
            display: block;
            background-color: $white;
            transition: right 0.3s ease-in-out, left 0.3s ease-in-out;
          }
          .top {
            top: -20px;
            bottom: 50%;
            left: 0;
            right: 100%;
          }
          .bottom {
            top: 50%;
            bottom: 0;
            left: 100%;
            right: 0;
          }

          &:hover {
            color: $primaryBlue;
            border: 2px solid transparent !important;
            background-color: $white;
            box-shadow: 0px 1px 26px 3px $primaryBlue;

            .top {
              right: 0;
            }
            .bottom {
              left: 0;
            }
          }
          &:last-child {
            margin-right: 0;
          }
        }
        .btn-filled {
          &:hover {
            color: $primaryBlue !important;
          }
        }
        .btn-transparent {
          border: 2px solid $white;

          &:hover {
            color: $primaryBlue !important;
          }
        }
      }
      .location {
        display: flex;
        align-items: center;
        margin-top: 20px;

        i {
          margin-right: 10px;
          color: $white;
          font-size: 20px;
        }
      }
    }
    .image-container {
      display: none;
      margin-top: 50px;
      flex: 0 0 100%;
      text-align: center;

      @media (min-width: 468px) {
        display: inline-block;
      }

      @media (min-width: 1100px) {
        margin-top: 0;
        flex: 0 0 calc(45% - 20px);
      }

      img {
        width: 80%;
        transition: transform 0.3s ease-in-out;

        @media (min-width: 700px) {
          max-width: 500px;
        }

        @media (min-width: 1100px) {
          width: 100%;
          max-width: auto;
        }

        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
}
</style>
