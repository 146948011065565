<template>
  <section id="contact-us-section" class="section">
    <div class="container">
      <div class="section-flex contact-us-container">
        <div class="left-side">
          <div
            class="title"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <span class="decoration">Comunicate con Nosotros</span>
            <h2>Contacto</h2>
          </div>
          <div
            class="content"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <p class="message">
              Llena el Formulario o
              <a href="#contact-methods" class="important"
                >Contactanos por Alguno de Estos Medios</a
              >
            </p>
            <div id="contact-methods" class="contact-info">
              <a href="mailto:info@fancy-solutions.com" class="contact-item">
                <div class="icon">
                  <i class="fas fa-envelope"></i>
                </div>
                <p class="text">info@fancy-solutions.com</p>
              </a>
              <a href="tel:3757 680001" class="contact-item">
                <div class="icon">
                  <i class="fas fa-phone-alt"></i>
                </div>
                <p class="text">3757 680001</p>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=5493757680001&text=Hola,+estoy+interesado/a+en+sus+servicios.&app_absent=0"
                class="contact-item"
              >
                <div class="icon">
                  <i class="fab fa-whatsapp"></i>
                </div>
                <p class="text">
                  3757 680001
                </p>
              </a>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          class="right-side form-container"
        >
          <div class="form-title flex-50">Envianos un Mensaje</div>
          <form @submit.prevent="submitMessage">
            <div class="form-item flex-50">
              <input
              required
                type="text"
                placeholder="Tu Nombre"
                :class="{ error: !name.isValid }"
                v-model.trim="name.value"
              />
              <p v-if="!name.isValid" class="error-text">Nombre No Valido!</p>
            </div>
            <div class="form-item flex-50">
              <input
              required
                type="text"
                placeholder="Tu Apellido"
                :class="{ error: !surname.isValid }"
                v-model.trim="surname.value"
              />
              <p v-if="!surname.isValid" class="error-text">
                Apellido No Valido!
              </p>
            </div>
            <div class="form-item flex-50">
              <input
              required
                type="email"
                placeholder="Tu Mail"
                :class="{ error: !mail.isValid }"
                v-model.trim="mail.value"
              />
              <p v-if="!mail.isValid" class="error-text">Mail No Valido!</p>
            </div>
            <div class="form-item flex-50">
              <input
              required
                type="tel"
                placeholder="Tu Numero de Telefono"
                :class="{ error: !phone.isValid }"
                v-model.trim="phone.value"
              />
              <p v-if="!phone.isValid" class="error-text">
                Telefono No Valido!
              </p>
            </div>
            <div class="form-item">
              <textarea
                placeholder="Tu Mensaje"
                :class="{ error: !message.isValid }"
                v-model.trim="message.value"
              ></textarea>
              <p v-if="!message.isValid" class="error-text">
                Mensaje No Valido!
              </p>
            </div>
            <div class="form-item">
              <button
                type="submit"
                class="btn btn-filled"
                :class="{ 'btn-disabled': getIsLoading }"
              >
                {{ getIsLoading ? "Enviando..." : "Consultar" }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed, reactive, ref } from "@vue/reactivity";
import axios from "axios";
import { inject } from "vue";
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
    const swal = inject("$swal");
    const isLoading = ref(false);
    const name = reactive({
      value: "",
      isValid: true,
    });
    const surname = reactive({
      value: "",
      isValid: true,
    });
    const mail = reactive({
      value: "",
      isValid: true,
    });
    const phone = reactive({
      value: "",
      isValid: true,
    });
    const message = reactive({
      value: "",
      isValid: true,
    });

    async function submitMessage() {
      if (name.value == "") {
        name.isValid = false;
      } else {
        name.isValid = true;
      }
      if (surname.value == "") {
        surname.isValid = false;
      } else {
        surname.isValid = true;
      }
      if (mail.value == "") {
        mail.isValid = false;
      } else {
        mail.isValid = true;
      }
      if (phone.value == "") {
        phone.isValid = false;
      } else {
        phone.isValid = true;
      }
      if (message.value == "") {
        message.isValid = false;
      } else {
        message.isValid = true;
      }

      if (
        name.isValid &&
        surname.isValid &&
        mail.isValid &&
        phone.isValid &&
        message.isValid
      ) {
        isLoading.value = true;
        try {
          await axios({
            method: "post",
            url: "https://usebasin.com/f/8db2180f3363",
            data: {
              name: name.value,
              surname: surname.value,
              mail: mail.value,
              phone: phone.value,
              message: message.value,
            },
          });
          swal(
            "Fantastico!",
            "Su consulta se ha Enviado Correctamente!",
            "success"
          );
        } catch (e) {
          isLoading.value = false;
          swal("Oops!", "Hubo un problema con Su Consulta!", "error");
        }
        name.value = "";
        surname.value = "";
        mail.value = "";
        phone.value = "";
        message.value = "";
        isLoading.value = false;
      }
    }

    return {
      name,
      surname,
      mail,
      phone,
      message,
      getIsLoading: computed(() => {
        return isLoading.value;
      }),
      submitMessage,
    };
  },
};
</script>

<style lang="scss" scoped>
.contact-us-container {
  .left-side {
    max-width: 100%;

    @media (min-width: 1100px) {
      flex: 0 0 calc(50% - 20px) !important;
    }
    .content {
      .message {
        color: $darkGray;
        .important {
          font-weight: 700;
          text-decoration: underline;
          color: $black;
          transition: color 0.3s ease-in-out;

          &:hover {
            color: $primaryBlue;
          }
        }
      }
      .contact-info {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .contact-item {
          flex: 0 0 100%;
          border-radius: 5px;
          display: flex;
          align-items: center;
          padding: 20px;
          margin-bottom: 20px;
          box-shadow: 0px 0px 5px -1px #00000028;
          transition: box-shadow 0.3s ease-in-out;
          text-decoration: none;
          overflow: hidden;

          &:hover {
            box-shadow: 5px 5px 20px 1px #00000028;
          }

          &:last-child {
            margin-bottom: 0;
          }

          @media (min-width: 900px) {
            margin-bottom: 0;
            flex: 0 0 calc(33.3% - 10px);
          }

          @media (min-width: 1100px) {
            flex: 0 0 100%;
            margin-bottom: 20px;

            &:last-child {
              margin-bottom: 0;
            }
          }

          .icon {
            height: 40px;
            flex: 0 0 40px;
            line-height: 40px;
            text-align: center;
            margin-right: 20px;
            border-radius: 50%;
            background-color: $primaryBlue;
            color: $white;
          }

          .text {
            flex: 0 0 200px;
            color: $darkGray;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex: 1;
          }
        }
      }
    }
  }
  .form-container {
    @media (min-width: 1100px) {
      flex: 0 0 calc(50% - 20px) !important;
    }

    .form-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 25px;
      color: $darkBlue;
    }
    form {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .form-item {
        margin-bottom: 18px;
        flex: 0 0 100%;

        &:last-child {
          margin-bottom: 0;
        }

        ::-webkit-scrollbar {
          width: 0;
        }

        input,
        textarea {
          width: 100%;
          background-color: $extremeLightGray;
          border: none;
          font-size: 16px;
          padding: 17px 20px;
          outline: none;
          border-radius: 5px;
          color: $darkGray;
          border: 2px solid transparent;
          transition: border 0.3s ease-in-out, color 0.3s ease-in-out,
            background-color 0.3s ease-in-out;

          &:focus {
            border: 2px solid $primaryBlue;
            background-color: $white;
          }

          &::placeholder {
            font-family: $poppins;
          }
        }
        textarea {
          resize: none;
          min-height: 200px;
        }
        .error {
          background-color: $lightRed !important;
          &:focus {
            border: 2px solid $red;
          }
        }
        .error-text {
          margin-top: 10px;
          color: $red;
          font-size: 14px;
        }
      }
      .flex-50 {
        flex: 0 0 100% !important;

        @media (min-width: 700px) {
          flex: 0 0 calc(50% - 10px) !important;
        }
      }
      .btn {
        min-width: 150px;
      }
    }
  }
}
</style>