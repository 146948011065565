<template>
  <section id="frequent-questions-section" class="section section-filled-light">
    <div class="container">
      <div
        class="title centered-title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <span class="decoration">Sacate tus Dudas</span>
        <h2>Preguntas Frecuentes</h2>
      </div>
      <div class="content">
        <div class="questions-container">
          <div
            class="image-container"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <img
              src="@/assets/img/questions/questions-image.png"
              alt="Preguntas Frecuentes"
              loading="lazy"
            />
          </div>
          <div
            class="questions"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div
              v-for="(item, index) in getQuestions"
              :key="index"
              class="question"
            >
              <div class="question-title" @click="changeActiveQuestion">
                <div class="left-question">
                  <div class="number">
                    <p>{{ index + 1 }}</p>
                  </div>
                  <div class="text">
                    <p>{{ item.question }}</p>
                  </div>
                </div>
                <div class="icon"><i class="fas fa-plus"></i></div>
              </div>
              <div class="question-description">
                <p>
                  {{ item.answer }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from "@vue/reactivity";
import $ from "jquery";
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
    const questions = [
      {
        question: "Por que elegirnos?",
        answer:
          "Es nuestra atención a las cosas pequeñas, la programación de los plazos y la gestión de proyectos entusiasta lo que nos hace destacar del resto. Somos creativos, mientras vigilamos de cerca el calendario y su presupuesto.",
      },
      {
        question: "En cuanto tiempo tendre mi proyecto listo?",
        answer:
          "Cada proyecto es unico, por lo que es dificil dar un estimativo. No obstante, haremos todo lo posible para llevar a cabo el proyecto de la mejor manera en el menor tiempo posible.",
      },
      {
        question:
          "Seguiran brindando soporte una vez que este el proyecto terminado?",
        answer:
          "Si. En caso de que lo desee, le seguiremos dando soporte a su proyecto incluso luego de que este finalizado.",
      },
    ];

    function changeActiveQuestion(e) {
      if (!$(e.target).parent().hasClass("question-active")) {
        $(e.target).parent().addClass("question-active");
        $(e.target).next().slideDown(300);
        $($(e.target).children().children()[2]).removeClass("fas fa-plus");
        $($(e.target).children().children()[2]).addClass("fas fa-minus");
      } else {
        $(e.target).parent().removeClass("question-active");
        $(e.target).next().slideUp(300);
        $($(e.target).children().children()[2]).removeClass("fas fa-minus");
        $($(e.target).children().children()[2]).addClass("fas fa-plus");
      }
    }

    return {
      getQuestions: computed(() => questions),
      changeActiveQuestion,
    };
  },
};
</script>

<style lang="scss" scoped>
.questions-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .image-container {
    display: none;
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.05);
    }

    @media (min-width: 950px) {
      display: inline-block;
      flex: 0 0 calc(50% - 50px);
    }

    @media (min-width: 1300px) {
      display: inline-block;
      flex: 0 0 calc(50% - 80px);
    }
  }
  .questions {
    flex: 0 0 100%;

    @media (min-width: 950px) {
      flex: 0 0 calc(50% - 20px);
    }

    .question {
      margin-bottom: 20px;
      box-shadow: 0px 0px 10px -2px #0000001e;
      border-radius: 3px;
      overflow: hidden;
      box-shadow: backgorund-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

      &:last-child {
        margin-bottom: 0;
      }

      .question-title {
        cursor: pointer;
        padding: 12px 17px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: backgorund-color 3s ease-in-out;
        background-color: $white;

        .left-question {
          flex: 1;
          pointer-events: none;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .number {
            display: block;
            background-color: $lightBlue;
            flex: 0 0 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            color: $darkBlue;
            font-weight: 700;
            margin-right: 20px;
          }
          .text {
            color: $darkBlue;
            font-weight: 500;
            font-size: 17px;
            transition: color 0.3s ease-in-out;
            width: 100%;
          }
        }
        .icon {
          display: inline-bloc;
          margin-left: 20px;
          margin-left: 20px;
          pointer-events: none;
          color: $darkBlue;
          transition: color 0.3s ease-in-out;
        }
      }
      .question-description {
        background-color: $white;
        display: none;
        padding: 20px;
        color: $darkGray;
        font-size: 15px;
      }
    }
    .question-active {
      box-shadow: none;

      .question-title {
        background-color: $darkBlue;

        .left-question {
          .text {
            color: $white;
            font-weight: 500;
            font-size: 17px;
          }
        }
        .icon {
          color: $white;
        }
      }
    }
  }
}
</style>