<template>
  <section id="services-section" class="section">
    <div class="container">
      <div
        class="title centered-title"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
      >
        <h2>Servicios</h2>
        <p class="subtitle">
          Fancy Solutions ofrece servicios de desarrollo de software de ciclo
          completo que se adaptan perfectamente a los requisitos de su proyecto
          y necesidades comerciales.
        </p>
      </div>
      <div class="content">
        <div class="services-container">
          <div
            class="service"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="icon">
              <i class="fas fa-medal"></i>
            </div>
            <div class="information">
              <p class="service-title">Calidad Asegurada</p>
              <p class="description">
                Quieres proveerle a tus usuarios una mejor experiencia
                tecnologica? Te tenemos cubierto. Nuestro equipo de
                profesionales te ayudara a digitalizar tu negocio.
              </p>
            </div>
          </div>
          <div
            class="service"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="icon">
              <i class="fas fa-headset"></i>
            </div>
            <div class="information">
              <p class="service-title">Soporte y Mantenimiento</p>
              <p class="description">
                En Fancy Solutions creemos que el desarrollo de un producto es
                un proceso continuo. Por eso nuestro equipo estara a su
                diposicion durante todo el ciclo de vida del producto.
              </p>
            </div>
          </div>
          <div
            class="service"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
          >
            <div class="icon">
              <i class="fas fa-crosshairs"></i>
            </div>
            <div class="information">
              <p class="service-title">Software a Medida</p>
              <p class="description">
                Sabemos crear software que se adapte a sus necesidades, para que
                pueda usar la tecnologia para mejorar su negocio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import AOS from "aos";

export default {
  setup() {
    AOS.init({
      once: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.services-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;

  @media (min-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1100px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .service {
    padding: 35px 30px;
    border: 1px solid $lightGray;
    border-radius: 20px;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out,
      border 0.3s ease-in-out;

    &:nth-child(1) {
      .icon {
        background-color: #00aeff;
      }
    }
    &:nth-child(2) {
      .icon {
        background-color: #7700ff;
      }
    }

    &:nth-child(3) {
      .icon {
        background-color: #ff5e00;
      }
      @media (min-width: 850px) {
        grid-column: 1/3;
      }
      @media (min-width: 1100px) {
        grid-column: auto;
      }
    }

    &:hover {
      border: 1px solid transparent;
      transform: translateY(-5px);
      box-shadow: 2px 12px 38px 1px #00000025;
    }

    .icon {
      height: 50px;
      width: 50px;
      color: $white;
      line-height: 50px;
      text-align: center;
      border-radius: 8px;
      font-size: 20px;
    }
    .information {
      margin-top: 25px;

      .service-title {
        font-size: 20px;
        font-weight: 600;
        color: $darkBlue;
      }
      .description {
        margin-top: 10px;
        color: $darkGray;
      }
    }
  }
}
</style>